import React, { Component } from 'react';
import ImmerseLogoWhite from '../../assets/svg/immmerse-white-logo.svg';
import CircleLineWhite from '../../assets/svg/circle-line-white.svg';
import CircleLinePurple from '../../assets/svg/circle-line-purple.svg';
import CircleFillWhite from '../../assets/svg/circle-white.svg';
import BackgroundHero from '../../assets/img/home_bg.png';
import PlantDecoLeft from '../../assets/svg/plant-2.svg';
import PlantDecoRight from '../../assets/svg/plant-1.svg';
import ImmerseLogoNew from '../../assets/svg/immerse-logo-new.svg';
export default class Hero extends Component {
    render() {
        let {
            backgroundImage, 
            description
        } = this.props;
        return (
            <div id="hero" className="container-fluid" style={{
                backgroundImage: `url(${backgroundImage ? backgroundImage.url : ''})`,
                backgroundRepeat:'no-repeat',
                backgroundPosition: '0 0',
                backgroundAttachment: 'fixed'
            }}>
                <img className="pos-abs top-left" src={CircleLineWhite} alt="Circle Line Decoration" />
                <img className="pos-abs top-right-center" src={CircleLinePurple} alt="Circle Line Decoration" />
                <img className="pos-abs top-left-center" src={CircleFillWhite} alt="Circle Line Decoration" />
                <img className="pos-abs top-right" src={CircleFillWhite} alt="Circle Line Decoration" />
                <img className="pos-abs bottom-right" src={CircleFillWhite} alt="Circle Line Decoration" />
                <img className="pos-abs bottom-right-2" src={CircleFillWhite} alt="Circle Line Decoration" />
                <img className="pos-abs plant-left-bottom" src={PlantDecoLeft} alt="Decoration plant" />
                <img className="pos-abs plant-right-bottom" src={PlantDecoRight} alt="Decoration plant" />
                <div className="row align-items-center">
                    <div className="col text-center">
                        <img className="center-brand" src={ImmerseLogoNew} alt="Immerse, Aruba Innovation" />
                        <h2 className="default-secondary-color mt-5">{description}</h2>
                    </div>
                </div>
            </div>
        );
    }
}