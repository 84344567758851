import React, { Component } from 'react';
import ImageVRGoggle from '../../assets/img/circle-img-contact.png';
import ContactUsForm from '../subComponents/contact';

export default class Contact extends Component {
    render() {
        let {
            mainTitle, 
            image
        } = this.props;
        return (
            <div id="contact" className="container-fluid triple-inset">
                <div className="row triple-inset align-items-center">
                    <div className="col-5 offset-md-1">
                        <img src={image.url} className="img-fluid" alt={mainTitle} />
                    </div>
                    <div className="col-5">
                        <h2 className="secondary-color mb-5">{mainTitle}</h2>
                        <ContactUsForm />
                    </div>
                </div>
            </div>
        );
    }
}