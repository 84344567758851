import React, { Component } from 'react';
import CircleImage from '../../assets/img/circle-img-about.png';

export default class About extends Component {
    render() {
        let {
            mainTitle, 
            description,
            image
        } = this.props;
        return (
            <div id="about" className="container-fluid triple-inset">
                <div className="row align-items-center">
                    <div className="col-5 offset-md-1">
                        <h2 className="secondary-color mb-3">{mainTitle}</h2>
                        <p className="quaternary-color" dangerouslySetInnerHTML={{ __html: `${description}` }} />
                    </div>
                    <div id="deco-image-right" className="col-6 h-900" style={{
                        backgroundImage: `url(${image.url})`
                    }}></div>
                </div>
            </div>
        );
    }
}