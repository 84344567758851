import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="container-fluid primary-bg-color inset">
                <div className="row">
                    <div className="col text-center">
                        <p className="default-secondary-color mb-0">By <a className="default-secondary-color" href="https://www.cr38te.com" target="_blank">CR38TE</a></p>
                    </div>
                </div>
            </footer>
        );
    }
}