import React, { Component } from 'react';
import Form from '../contact/form';

export default class ContactIndex extends Component {

    constructor(props) {
        super(props);

        this.state = {
            registrationLoading: false,
            showFormError: false,
            showFormSuccess: false,
        }
    }

    onRegister = async (results) => {
        let errors = results.map(r => r.error).filter(e => e != null);
        if (errors.length > 0) {
            this.setState({ showFormError: true, showFormSuccess: false });
        }
        else {
            this.setState({ showFormError: false, showFormSuccess: true });

            let formDataStr = '';

            results.forEach((n, i) => {
                formDataStr += `${n.id}=${encodeURIComponent(n.value)}&`;
            });

            fetch('https://immersearuba.org/contact_form_submit.php', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*'
                },
                body: formDataStr
            })
            .then(response =>
                response.json()
            )
            .then(async (responseJson) => {
                console.log(responseJson);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(_ => {
                this.setState({ validating: false });
            });
        }
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.state;
        return (
            <Form
                register={this.onRegister}
                info={this.info}
                showFormError={showFormError}
                showFormSuccess={showFormSuccess}
                registrationLoading={registrationLoading}
            />
        );
    }
}