import React, { Component } from 'react';

import Supernova from '../../assets/img/supernova.png';
import Spaces from '../../assets/img/spaces.png';
import Futura from '../../assets/img/futura.png';
import FuturaCafe from '../../assets/img/futura-cafe.png';
import CentraleBank from '../../assets/img/centrale-bank.png';
import Atech from '../../assets/img/atech.png';
import AAA from '../../assets/img/aaa.png';
import ATC from '../../assets/img/atc.png';
import ELITE from '../../assets/img/elite.png';
import GAMMA from '../../assets/img/gamma.png';
import AITO from '../../assets/img/island-take-over.png';

export default class PartnerItem extends Component {
    render() {
        let {
            partnerName, 
            partnerLogo
        } = this.props;
        return (
            <div className="col-3">
                <img src={`${partnerLogo.url}`} alt={partnerName} />
            </div>
        );
    }
}