import React, { Component } from 'react';
import PreloaderSVG from '../../assets/svg/oval.svg';

export default class Preloader extends Component {
    render() {
        return (
            <div id="preloader" className="container-fluid">
                <div className="row align-items-center">
                    <div className="col text-center">
                        <img className="preloader-svg" src={PreloaderSVG} />
                    </div>
                </div>
            </div>
        );
    }
}