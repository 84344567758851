import React, { Component } from 'react';

export default class Table extends Component {
    
    render() {
        let { index, eventos } = this.props;

        
        return (
            <div className="tab-content">
                <div id={`${index}-tab-item`} className={`tab-pane fade ${index === 0 ? 'show active' : '' }`}>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date &amp; Time</th>
                                    <th scope="col">Event</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Registration</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {
                                    events &&
                                    events.map(( props , i) => {
                                        console.log(props);
                                        return(
                                            <tr key={`row-item-${i}`}>
                                                <td>{eventName}</td>
                                                <td>{acf.eventDate}</td>
                                                <td>{acf.eventName}</td>
                                                <td>
                                                    <div dangerouslySetInnerHTML={{ __html: `${acf.description}` }} />
                                                </td>
                                                <td>{acf.registrationLink}</td>
                                            </tr>
                                        )
                                    })
                                } */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}