import React from 'react';
import IMG from '../../assets/img/600x800.png';

const EventModal = ({ onClick, ...props }) => {
    return (
        <div className="Modal">
            <div onClick={onClick} className="icon-close">
                x
            </div>
            <div className="ModalContent">
                <div className="ModalContainer">
                    <div className="ModalRow">
                        <div className="ModalColumn width-40">
                            {props.props.acf.eventFlyer ? 
                                <img src={props.props.acf.eventFlyer} />
                            :
                                <img src={IMG} />
                            }
                        </div>
                        <div className="ModalColumn width-60 scrollColumn">
                            <h2 className="default-primary-color mb-2">{props.props.acf.eventName}</h2>
                            <div className="items mb-3">
                                <div><strong>Date:</strong> <br />{props.props.acf.eventDate}</div>
                                <div><strong>Time:</strong> <br />
                                    {props.props.acf.eventTimeStart} 
                                    {props.props.acf.eventTimeEnd !== '' && <> - </>} 
                                    {props.props.acf.eventTimeEnd}
                                </div>
                                <div>
                                    {props.props.acf.registrationLink &&
                                        <a href={props.props.acf.registrationLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Register</a>
                                    }
                                </div>
                            </div>
                            <div className="items mb-3">
                                <div><strong>Hosted by:</strong> <br />{props.props.acf.hostedBy ? props.props.acf.hostedBy : '-'}</div>
                                <div><strong>Location:</strong> <br />{props.props.acf.eventLocation ? props.props.acf.eventLocation : '-'}</div>
                            </div>
                            <div className="modalParagraph">
                                <div dangerouslySetInnerHTML={{ __html: `${props.props.acf.eventLongDescription}` }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EventModal;