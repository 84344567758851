import React, { Component } from 'react';
import LogoImmerse from '../../assets/svg/immmerse-white-logo.svg';
import ImmerseLogoNew from '../../assets/svg/immerse-logo-new.svg';
export default class Aside extends Component {
    render() {
        return (
            <aside id="complementary" className="container-fluid primary-bg-color double-inset">
                <div className="row">
                    <div className="col-2 offset-md-5 text-center">
                        <img src={ImmerseLogoNew} alt="Immerse Aruba Innovation Week" />
                    </div>
                </div>
            </aside>
        );
    }
}