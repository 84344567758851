import React, { Component } from 'react';
import ImmerseLogoWhite from '../../assets/svg/immmerse-white-logo.svg';
import ImmerseLogoNew from '../../assets/svg/immerse-logo-new.svg';
import { Link , Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
export default class Header extends Component {
    constructor() {
        super();
        this.state = {
            color:'',
            mobileNavVisible:true
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        Events.scrollEvent.register('begin', function(to, element) {
          //console.log("begin", to, element);
        });

        Events.scrollEvent.register('end', function(to, element) {
          //console.log("end", to, element);
        });

        scrollSpy.update();

    };

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
        window.removeEventListener('scroll', this.handleScroll);
    };

    handleSetActive(to) {
        //console.log(to);
    };

    handleScroll = () => {
        if (window.scrollY <= (document.getElementById('hero').clientHeight) - 100 ) {
            this.setState({
                color:''
            });
        } else {
            this.setState({
                color:'rgba(23,42,58,0.7)'
            });
            //console.log('current biget then 0');
        }
    }

    renderMobileNav() {
        if(!this.state.mobileNavVisible) {
            this.setState({mobileNavVisible: true});
        } else {
            this.setState({mobileNavVisible: false});
        }
    }
    
    render() {
        const visibility = this.state.mobileNavVisible ? {} : {'display':'flex'};
        let { color } = this.state;
        return (
            <header id="header" className="container-fluid" style={{
                backgroundColor: `${color}`
            }}>
                <div className="row align-items-center">
                    <div className="col-2">
                        <h1 className="pb-0 mb-0">
                            <a href="/">
                                <img className="brand" src={ImmerseLogoNew} alt="Immerse, Aruba Innovation" />
                            </a>
                        </h1>
                    </div>
                    <div className="col-10 pr-0">
                        <span id="mobile-nav" onClick={() => this.renderMobileNav()}>
                            <i className="fas fa-ellipsis-h default-secondary-color"></i>
                        </span>
                        <nav id="main-nav" className="pull-right" style={visibility}>
                            <ul className="nav">
                                <li className="nav-item">
                                    <Link activeClass="active" to="hero" hashSpy={true} spy={true} smooth={true} offset={0} duration={350} onSetActive={this.handleSetActive}
                                        onClick={() => this.renderMobileNav()}
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="about" hashSpy={true} spy={true} smooth={true} offset={160} duration={350} onSetActive={this.handleSetActive}
                                        onClick={() => this.renderMobileNav()}
                                    >
                                        About
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="program" hashSpy={true} spy={true} smooth={true} offset={255} duration={350} onSetActive={this.handleSetActive}
                                        onClick={() => this.renderMobileNav()}
                                    >
                                        Program
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="partners" hashSpy={true} spy={true} smooth={true} offset={220} duration={350} onSetActive={this.handleSetActive}
                                        onClick={() => this.renderMobileNav()}
                                    >
                                        Partners
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="contact" hashSpy={true} spy={true} smooth={true} offset={125} duration={350} onSetActive={this.handleSetActive}
                                        onClick={() => this.renderMobileNav()}
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}