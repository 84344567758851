import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import DynamicScrollToTop from '../src/components/subComponents/scrollToTop';
import Preloader from '../src/components/subComponents/preloader';

// STYLESHEET
import './assets/css/app.min.css';

// GLOBAL
import Header from '../src/components/header';
import Aside from '../src/components/aside';
import Footer from '../src/components/footer';

// COMPONENTS
import HeroArea from '../src/components/subComponents/heroArea';
import AboutSection from '../src/components/subComponents/aboutSection';
import ScheduleSection from '../src/components/subComponents/scheduleSection';
import PartnersSection from '../src/components/subComponents/partnersSection';
import ContactSection from '../src/components/subComponents/contactSection';
import SubscribeForm from '../src/components/subComponents/subscribeForm';

export default class App extends Component {
	constructor(props) {
        super(props);
        this.state = {
			fetching: true
		}
	}

	componentDidMount() {
		fetch(`https://immersearuba.org/wp-json/acf/v3/pages/2`)
            .then(response => response.json())
            .then(({acf}) => this.setState({ ...acf, fetching: false }))
            .catch(error => console.error(error))
	}

	render() {
		let {
            fetching,
            heroGroup,
            aboutGroup,
            partnersGroup,
            contactGroup,
            subscribeGroup,
			programGroup,
			programTitle,
			calendarOfEvents
		} = this.state;
		if (fetching) return <Preloader />;
		
		return (
			<Router>
				<div className="App">
					<DynamicScrollToTop />
					<Header />
						<main id="main">
							<HeroArea {...heroGroup}  />	
							<AboutSection {...aboutGroup} />
							<ScheduleSection programGroup={programGroup} programTitle={programTitle} eventGroup={calendarOfEvents} />
							<PartnersSection {...partnersGroup} />	
							<ContactSection {...contactGroup} />	
							<SubscribeForm {...subscribeGroup} />
						</main>
					<Aside />
					<Footer />
				</div>
			</Router>
		);
	}
}