import React, { Component } from 'react';
import {
    Validator,
    TextField,
    TextAreaField,
    ValueSetValidator,
    EmailValidator,
    SelectValidator,
} from '../../input';
import CurveTop from '../../../assets/svg/curve.svg';

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.validator = new Validator();
    }

    register = e => {
        if (this.props.register) {
              this.props.register(this.validator.validResult());
        }
        e.preventDefault();
    }

    render() {
        let { showFormError, showFormSuccess, registrationLoading } = this.props;
        showFormError = showFormError || false
        showFormSuccess = showFormSuccess || false;

        let sendStatus = 'initial';
        if (registrationLoading) sendStatus = 'processing';
        else if (showFormSuccess) sendStatus = 'done';

        return (
            <form id="contact-form" className="form row mb-5" noValidate style={{
                backgroundImage: `url(${CurveTop})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
                backgroundPosition: '0 -50px'
            }}>
                {showFormError &&
                    <div id="feedback" className="col-12">
                        <div className="alert alert-danger">
                            <div className="alert default-primary-color mb-0">
                                <i className="fas fa-exclamation-triangle"></i> There are some fields missing or incorrect. Please check them and try again.
                            </div>
                        </div>
                    </div>
                }
                {showFormSuccess &&
                    <div id="feedback" className="col-12">
                        <div className="alert alert-success">
                            <div className="alert default-primary-color mb-0">
                                <i className="fas fa-check-circle"></i> Thank you for your filling in the contact form. We will contact you as soon as possible.
                            </div>
                        </div>
                    </div>
                }
                <div className="form-group col-6">
                    <label htmlFor="firstName">First Name *</label>
                    <TextField
                        id='firstName'
                        placeholder='...'
                        validator={new ValueSetValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="lastName">Last Name *</label>
                    <TextField
                        id='lastName'
                        placeholder='...'
                        validator={new ValueSetValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="emailAddress">Email *</label>
                    <TextField
                        id='emailAddress'
                        placeholder='...'
                        validator={new EmailValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="telephoneNumber">Telephone Number *</label>
                    <TextField
                        id='phoneNumber'
                        placeholder='...'
                        validator={new ValueSetValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="companyName">Company Name *</label>
                    <TextField
                        id='companyName'
                        placeholder='...'
                        validator={new ValueSetValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-6">
                    <label htmlFor="subject">Subject *</label>
                    <TextField
                        id='contactSubject'
                        placeholder='...'
                        validator={new ValueSetValidator(this.validator)}
                    />
                </div>
                <div className="form-group col-12">
                    <label htmlFor="message">Message *</label>
                    <TextAreaField validator={new ValueSetValidator(this.validator)}
                        id='messageCompany'
                        placeholder='...'
                        className="form-control"
                    />
                </div>
                <div className="col-12">
                    <br />
                    <div className={"btn btn-primary " + sendStatus} onClick={this.register} disabled={(['processing', 'done'].includes(sendStatus))}>
                        {sendStatus === 'initial' && 'Send'}
                        {sendStatus === 'processing' && <div>Submitting</div>}
                        {sendStatus === 'done' && 'Done!'}
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactForm;