import React, { Component } from 'react';
import TabNavigation from '../../components/subComponents/tabNavigation';
import TableSchedule from '../../components/subComponents/tableSchedule';
import CircleWhite from '../../assets/svg/circle-white.svg';
import CirclePurple from '../../assets/svg/circle-purple.svg';
import CircleLineWhite from '../../assets/svg/circle-line-white.svg';
import Preloader from '../../components/subComponents/table-preloader';
import EventModal from '../subComponents/modalEvent';
export default class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            eventos: [], 
            openModal: false,
            currentEvent: {},
		}
	}

    componentDidMount() {
        const eventGroup = this.props.eventGroup;
        
        if(eventGroup && eventGroup.length > 0) {
            const events = eventGroup[0].events;

            let arrayElOne = '';
            let arrayEls = '';

            events.map((eventItem, j) => {
                j === 0 ? 
                    arrayElOne += `&include[]=${eventItem}` 
                : 
                    arrayEls += `&include[]=${eventItem}`;
            });      

            let newArr = (arrayElOne.concat(arrayEls)).toString();

            fetch(`https://immersearuba.org/wp-json/acf/v3/event?per_page=99${newArr}`)
                .then(response => response.json())
                .then((eventos) => this.setState({ eventos, fetching: false }))
                .catch(error => console.error(error))
        }
    }

    handleCallback = (event) => {
        this.setState({ fetching: true });
        const currentTab = event.currentTarget.getAttribute('data-href');
        const currentEvents = event.currentTarget.getAttribute('data-events');
        const currentId = event.currentTarget.getAttribute('data-id');

        const eventsArr = currentEvents.split(',');
        
        event.preventDefault();     

        let tabPanes = document.getElementsByClassName('tab-pane');
        let tabNavs = document.getElementsByClassName('nav-link');

        for (let i = 0; i < tabPanes.length; i++) {
            tabPanes[i].classList.remove('show');
            tabPanes[i].classList.remove('active');
            tabNavs[i].classList.remove('active');
        }

        document.getElementById(`${currentTab}`).classList.add('show');
        document.getElementById(`${currentTab}`).classList.add('active');
        document.getElementById(`${currentId}`).classList.add('active');

        let arrayElOne = '';
        let arrayEls = '';

        eventsArr.map((eventItem, j) => {
            j === 0 ? 
                arrayElOne += `&include[]=${eventItem}` 
            : 
                arrayEls += `&include[]=${eventItem}`;
        });      
        
        let newArr = (arrayElOne.concat(arrayEls)).toString();

        fetch(`https://immersearuba.org/wp-json/acf/v3/event?per_page=99${newArr}`)
            .then(response => response.json())
            .then((eventos) => this.setState({ eventos, fetching: false, }))
            .catch(error => console.error(error))
    }

    toggleModal = (e, eEvent) => {
        e.preventDefault();
        this.setState({ 
            currentEvent: eEvent,
            openModal: true
        });
    }

    closeModal = () => {
        this.setState({ 
            openModal: false
        });
    }

    render() {
        let {
            eventGroup,
            programTitle
        } = this.props;

        let {
            fetching,
            eventos,
            openModal,
            currentEvent
		} = this.state;

        const sortEventByDate = eventos.sort((a, b) => (a.acf.eventDate > b.acf.eventDate) ? 1 : -1);

        return (
            <div id="program" className="container-fluid triple-inset secondary-bg-color">
                <img className="pos-abs top-left" src={CircleWhite} alt="Circle Line Decoration" />
                <img className="pos-abs top-left-2" src={CirclePurple} alt="Circle Line Decoration" />
                <img className="pos-abs top-right" src={CircleLineWhite} alt="Circle Line Decoration" />
                <div className="row triple-inset">
                    <div className="col-10 offset-md-1 mt-5 pt-5">
                        <h2 className="default-secondary-color mt-5 pt-5 mb-5">{programTitle}</h2>
                        <ul className="nav nav-tabs" id="schedule-nav" role="tablist">
                            {
                                eventGroup &&
                                eventGroup.map(({ events, title }, i) =>  {
                                    return(
                                        <TabNavigation index={i} key={i} title={title} events={events || []} onClick={this.handleCallback} data-events={events || []} />
                                    );
                                })
                            }
                        </ul>

                        {
                            eventGroup &&
                            eventGroup.map(({ props }, i) => {   
                                return(
                                    <div className="tab-content" key={`${i}-tab-content`}>
                                        <div id={`${i}-tab-item`} className={`tab-pane fade ${i === 0 ? 'show active' : '' }`}>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Date &amp; Time</th>
                                                            <th scope="col">Hosted by</th>
                                                            <th scope="col">Activity</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Info</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !fetching && sortEventByDate && sortEventByDate.length > 0 ?
                                                                sortEventByDate.map(( props , i) => {
                                                                    return(
                                                                        <tr key={`row-item-${i}`} onClick={(e) => this.toggleModal(e, {props})}>
                                                                            <td>
                                                                                {props.acf.eventDate}
                                                                                <br />
                                                                                <span className="timeRange">
                                                                                    {props.acf.eventTimeStart} 
                                                                                    {props.acf.eventTimeStart !== '' && <>-</>} 
                                                                                    {props.acf.eventTimeEnd}
                                                                                </span>
                                                                            </td>
                                                                            <td>{props.acf.hostedBy}</td>
                                                                            <td className="description">{props.acf.eventName}</td>
                                                                            <td>{props.acf.eventLocation}</td>
                                                                            <td>
                                                                                {props.acf.registrationLink &&
                                                                                    <a href={props.acf.registrationLink} target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Register</a>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            :
                                                            <tr key={`row-item-${i}`}>
                                                                <td colSpan="5" className="centered">
                                                                    {fetching ? 
                                                                        <Preloader />
                                                                    :
                                                                        <>No events...</>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                                {openModal && <EventModal onClick={this.closeModal} {...currentEvent} />}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}