import React, { Component } from 'react';

export default class Subscribe extends Component {
    render() {
        let {
            mainTitle, 
            description,
            buttonTitle,
            input_placeholder,
        } = this.props;
        return (
            <div id="sidebar" className="container-fluid pb-5">
                <div className="row">
                    <div className="col-4 offset-md-4 text-center">
                        <h3 className="secondary-color">{mainTitle}</h3>
                        <p className="quaternary-color">{description}</p>
                        <form className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1A86E12E27539E466BD271025FB049F08C4B356934D1DF7D93A57556BC3E8064F24F63A1D374635DC058D7010BD9AE3CAE">
                            <input aria-label="Email" className="js-cm-email-input" id="fieldEmail" maxLength="200" name="cm-yhdhjii-yhdhjii" required="" type="email" placeholder={input_placeholder} />
                            <button className="btn btn-secondary" type="submit">{buttonTitle}</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}