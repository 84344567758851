import React, { Component } from 'react';
import CirclePurple from '../../assets/svg/circle-purple.svg';
import Preloader from '../../components/subComponents/preloader';

export default class Tabs extends Component {
    render() {

        let { title, index, onClick, events } = this.props;

        return (
            <li className="nav-item">
                <a className={`nav-link ${index === 0 ? 'active' : '' }`} 
                id={`${index}-tab`} 
                data-toggle="tab" 
                data-id={`${index}-tab`}
                data-href={`${index}-tab-item`} 
                data-events={events}
                href={`#${index}-tab-item`} 
                role="tab"
                    onClick={onClick}  
                >
                    {title}
                </a>
            </li>
        );
    }
}