import React, { Component } from 'react';
import CurveTop from '../../assets/svg/curve.svg';
import CircleLinePurple from '../../assets/svg/circle-line-purple.svg';
import PartnerItems from '../../components/subComponents/partnerItems';

export default class Partner extends Component {
    render() {
        let {
            mainTitle, 
            partnerInfo
        } = this.props;
        return (
            <div id="partners" className="container-fluid triple-inset" style={{
                backgroundImage: `url(${CurveTop})`,
                backgroundRepeat:'no-repeat',
                backgroundSize:'101%',
                backgroundPosition: '-1px -220px'
            }}>
                <div className="row">
                    <div className="col-10 offset-md-1 mt-5 pt-5 text-center">
                        <img className="pos-abs top-left" src={CircleLinePurple} alt={mainTitle} />
                        <h2 className="secondary-color mt-5 pt-5 mb-5">{mainTitle}</h2>
                        <div id="partner-logo-container" className="row justify-content-center">
                            {
                                partnerInfo &&
                                partnerInfo.map((partner, i) =>  
                                    <PartnerItems {...partner} key={`partner-item-${i}`} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}